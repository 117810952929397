import "./style.css";
import * as THREE from "three";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls";

// resuable variables
let camera, scene, renderer, controls, raycaster;
let analyser1, analyser2, analyser3, analyser4;

// movements
let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let canJump = false;

let prevTime = performance.now();
const velocity = new THREE.Vector3();
const direction = new THREE.Vector3();

window.onload = () => {
  init();
  animate();
};

function init() {
  // camera
  camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    1,
    10000
  );
  camera.position.y = 20;

  // scene
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x43b7da);

  // light
  const light = new THREE.HemisphereLight(0xeeeeff, 0x777788, 0.75);
  light.position.set(0.5, 1, 0.75);
  scene.add(light);

  // controls
  controls = new PointerLockControls(camera, document.body);
  const overlay = document.getElementById("overlay");
  const btnPlay = document.getElementById("btnPlay");

  btnPlay.addEventListener("click", () => {
    controls.lock();
  });

  controls.addEventListener("lock", () => {
    btnPlay.style.display = "none";
    overlay.style.display = "none";
  });

  controls.addEventListener("unlock", () => {
    overlay.style.display = "flex";
    btnPlay.style.display = "";
  });

  scene.add(controls.getObject());

  // movements on keyboard
  const onKeyDown = function (event) {
    switch (event.code) {
      case "ArrowUp":
      case "KeyW":
        moveForward = true;
        break;

      case "ArrowLeft":
      case "KeyA":
        moveLeft = true;
        break;

      case "ArrowDown":
      case "KeyS":
        moveBackward = true;
        break;

      case "ArrowRight":
      case "KeyD":
        moveRight = true;
        break;

      case "Space":
        if (canJump === true) velocity.y += 400;
        canJump = false;
        break;
    }
  };

  const onKeyUp = function (event) {
    switch (event.code) {
      case "ArrowUp":
      case "KeyW":
        moveForward = false;
        break;

      case "ArrowLeft":
      case "KeyA":
        moveLeft = false;
        break;

      case "ArrowDown":
      case "KeyS":
        moveBackward = false;
        break;

      case "ArrowRight":
      case "KeyD":
        moveRight = false;
        break;
    }
  };

  document.addEventListener("keydown", onKeyDown);
  document.addEventListener("keyup", onKeyUp);

  raycaster = new THREE.Raycaster(
    new THREE.Vector3(),
    new THREE.Vector3(0, -1, 0),
    0,
    10
  );

  // create floor
  let floorGeometry = new THREE.PlaneGeometry(150, 150);
  const floorMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
  });

  floorGeometry.rotateX(-Math.PI / 2);

  const floor = new THREE.Mesh(floorGeometry, floorMaterial);
  scene.add(floor);

  // listener
  const listener = new THREE.AudioListener();
  camera.add(listener);

  // materials
  const boxGeometry = new THREE.BoxGeometry(8, 8, 8);
  const material = new THREE.MeshBasicMaterial({ color: 0x222222 });

  const boxBass = new THREE.Mesh(boxGeometry, material);
  const boxDrums = new THREE.Mesh(boxGeometry, material);
  const boxMelodies = new THREE.Mesh(boxGeometry, material);
  const boxPiano = new THREE.Mesh(boxGeometry, material);

  boxBass.position.set(-50, 4, 0);
  boxDrums.position.set(50, 4, 0);
  boxMelodies.position.set(0, 4, 50);
  boxPiano.position.set(0, 4, -50);
  scene.add(boxBass);
  scene.add(boxDrums);
  scene.add(boxMelodies);
  scene.add(boxPiano);

  // sounds
  const bass = new THREE.PositionalAudio(listener);
  const drums = new THREE.PositionalAudio(listener);
  const melodies = new THREE.PositionalAudio(listener);
  const piano = new THREE.PositionalAudio(listener);

  const songBassElement = document.getElementById("bass");
  bass.setMediaElementSource(songBassElement);
  bass.setRefDistance(12);

  const soungDrumsElement = document.getElementById("drums");
  drums.setMediaElementSource(soungDrumsElement);
  drums.setRefDistance(12);

  const songMelodiesElement = document.getElementById("melodies");
  melodies.setMediaElementSource(songMelodiesElement);
  melodies.setRefDistance(12);

  const songPianoElement = document.getElementById("piano");
  piano.setMediaElementSource(songPianoElement);
  piano.setRefDistance(12);

  bass.setDirectionalCone(180, 200, 0.1);
  drums.setDirectionalCone(180, 200, 0.1);
  melodies.setDirectionalCone(180, 200, 0.1);
  piano.setDirectionalCone(180, 200, 0.1);

  boxBass.add(bass);
  boxDrums.add(drums);
  boxMelodies.add(melodies);
  boxPiano.add(piano);

  // play sound
  btnPlay.addEventListener("click", () => {
    listener.context.resume();
    songBassElement.play();
    soungDrumsElement.play();
    songMelodiesElement.play();
    songPianoElement.play();
  });

  // analysers
  analyser1 = new THREE.AudioAnalyser(bass, 32);
  analyser2 = new THREE.AudioAnalyser(drums, 32);
  analyser3 = new THREE.AudioAnalyser(melodies, 32);
  analyser4 = new THREE.AudioAnalyser(piano, 32);

  // renderer
  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);

  window.addEventListener("resize", onWindowResize);
}

// resize
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

// animate
function animate() {
  requestAnimationFrame(animate);

  const time = performance.now();

  if (controls.isLocked === true) {
    const intersections = raycaster.intersectObjects([], false);

    const onObject = intersections.length > 0;

    const delta = (time - prevTime) / 1000;

    velocity.x -= velocity.x * 10.0 * delta;
    velocity.z -= velocity.z * 10.0 * delta;

    velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

    direction.z = Number(moveForward) - Number(moveBackward);
    direction.x = Number(moveRight) - Number(moveLeft);
    direction.normalize(); // this ensures consistent movements in all directions

    if (moveForward || moveBackward) velocity.z -= direction.z * 400.0 * delta;
    if (moveLeft || moveRight) velocity.x -= direction.x * 400.0 * delta;

    if (onObject === true) {
      velocity.y = Math.max(0, velocity.y);
      canJump = true;
    }

    controls.moveRight(-velocity.x * delta);
    controls.moveForward(-velocity.z * delta);

    controls.getObject().position.y += velocity.y * delta; // new behavior

    if (controls.getObject().position.y < 10) {
      velocity.y = 0;
      controls.getObject().position.y = 10;

      canJump = true;
    }
  }

  prevTime = time;

  renderer.render(scene, camera);
  render();
}

function render() {
  renderer.render(scene, camera);
}
